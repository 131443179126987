import React from "react";
import { Link } from "gatsby";

//components
import Layout from "../components/layout";
import AbReveal from "../components/abReveal/abReveal";
import AbWatermark from "../components/abWatermark/abWatermark";
import CookiePopup from "../components/cookiePopup/cookiePopup";

export default function Page404() {
  return (
    <Layout>
      <AbReveal />
      <CookiePopup />
      <AbWatermark />

      {/* Header */}
      <div className="container pt-5">
        <div className="row">
          <div className="col">
            <Link to="/"><img src="/assets/imgs/logo-ab.svg" alt="Logo Andrea Balbo" width="50"/></Link>
          </div>
        </div>
      </div>

    </Layout>
  );
}
